import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Liliana Flores",
        image: "assets/images/ceo.png",
        post: "Terapeuta de Sanación Física, Emocional y Espiritual",
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto text-center">
                  <h2 className="font-weight-light">
                    Un estudio digital especializado en terapias de sanación y experiencias espirituales
                  </h2>
                  <p className="text-muted pt-4">
                    En Yosoylaquesana, nos dedicamos a equilibrar cuerpo, mente y espíritu, ofreciendo terapias de sanación física, emocional y espiritual. Nuestros talleres y sesiones de cirugía astral están diseñados para liberar energías densas y mejorar tu bienestar general. Con nuestra experiencia y enfoque holístico, ayudamos a elevar la conciencia colectiva y promover una mayor armonía en la vida de nuestros clientes.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-center align-items-center">
              {/* Renderizar miembros del equipo */}
              {this.state.members.map((member, key) => (
                <TeamBox
                  key={key}
                  name={member.name}
                  image={member.image}
                  post={member.post}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
