import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Terapia de Sanación Integral",
        icon: "pe-7s-leaf", // icono de spa para la sanación integral
        description:
          "Equilibramos el cuerpo, mente y espíritu con técnicas modernas para lograr la armonía y la liberación de la esencia divina.",
      },
      {
        title: "Cirugía Astral Especializada",
        icon: "pe-7s-star", // icono de estrella para cirugía astral
        description:
          "Nuestra especialidad en cirugía astral trabaja con el campo energético para liberar bloqueos emocionales y físicos.",
      },
      {
        title: "Talleres de Aprendizaje Espiritual",
        icon: "pe-7s-bookmarks", // icono de libro para talleres de aprendizaje
        description:
          "Ofrecemos talleres para elevar la conciencia colectiva y promover una mayor evolución social y armonía.",
      },
      {
        title: "Soporte Personalizado",
        icon: "pe-7s-help2", // icono de ayuda para soporte personalizado
        description:
          "Brindamos un apoyo integral y personalizado para que cada individuo alcance un bienestar completo.",
      },
      {
        title: "Enfoque Integral",
        icon: "pe-7s-settings", // icono de ajustes para enfoque integral
        description:
          "Nuestros servicios están diseñados para ofrecer un enfoque completo y holístico a la sanación y el bienestar.",
      },
      {
        title: "Fácil Adaptación",
        icon: "pe-7s-piggy", // icono de ajuste para fácil adaptación
        description:
          "Nuestros tratamientos se adaptan fácilmente a las necesidades individuales, garantizando una experiencia personalizada.",
      },
      {
        title: "Diseño y Método Perfecto",
        icon: "pe-7s-note2", // icono de regla para diseño y método perfecto
        description:
          "Aplicamos métodos precisos y efectivos para asegurar resultados óptimos en cada sesión de terapia.",
      },
      {
        title: "Herramientas Especializadas",
        icon: "pe-7s-tools", // icono de herramientas para herramientas especializadas
        description:
          "Utilizamos herramientas especializadas para asegurar que cada terapia sea realizada con la máxima eficacia.",
      },
      {
        title: "Diseño Excepcional",
        icon: "pe-7s-paint", // icono de pintura para diseño excepcional
        description:
          "Nuestro enfoque en el diseño de las terapias garantiza una experiencia única y enriquecedora para cada cliente.",
      },
    ]

  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Nuestros Servicios"
              description="Ofrecemos una gama de terapias y talleres especializados para equilibrar el cuerpo, mente y espíritu. Nuestro enfoque integral incluye la cirugía astral y el aprendizaje espiritual, brindando soluciones personalizadas para el bienestar y la evolución personal."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
